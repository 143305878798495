export function loadHubSpotChat(hubSpotId: string) {
	if (typeof window === 'undefined') return // Ensure it runs only on the client side

	if (document.getElementById('hs-script-loader')) {
		// Script already loaded
		return
	}

	const script = document.createElement('script')
	script.id = 'hs-script-loader'
	script.src = `https://js.hs-scripts.com/${hubSpotId}.js`
	script.async = true
	document.body.appendChild(script)
}