import { SignIn } from '@clerk/remix'

import { ClerkLayout } from './sign-up.$'

export default function SignInPage() {
	return (
		<>
			<div className="flex max-w-4xl mx-auto mt-20 align-middle w-max gap-4 flex-row-reverse p-12 bg-[#211a49] rounded-lg">
				<div>
					<SignIn
						appearance={{
							layout: ClerkLayout,
						}}
						fallbackRedirectUrl="/"
					/>
				</div>
			</div>
		</>
	)
}
